@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;700&display=swap');

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  background-color: #F2F2F2;
  font-family: "Quicksand", sans-serif;
}

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;;
} */


.btn-analyse-div{
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
}
.btn-analyse{
    background-color: #0D2457;
    color: white;
    border:none;
    padding: 10px 20px;
    border-radius: 5px;
}
.col-sm-3{
    margin-bottom: 20px;
}

.cardd {
  width: 90%;
  margin: auto;
  max-width: 450px;
  background-color: #FFF;
  padding: 0 2rem 1.5rem 2rem;
  border-radius: 10px;
  transition: box-shadow .3s linear;
}
.cardd:hover {
  box-shadow: 0 0 35px rgba(0, 0, 0, .3);
}

.cardd .cardd-img {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 25px rgba(0, 0, 0, .3);
  transform: translateY(-15%);
  transition: box-shadow .3s linear;
}
.cardd:hover .cardd-img {
  box-shadow: none;
  background-color: #0D2457;
  color:white;
}
.cardd .cardd-img img {
  width: 100%;
  display: block;
}

.cardd .cardd-content {
  text-align: center;
}
.cardd .cardd-content .big-title {
  color: #333;
  font-size: 1.3rem;
}
.cardd .cardd-content .medium-title {
  color: #999;
  font-size: .85rem;
  margin-top: .3rem;
}