.head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 5rem;
    box-shadow: 2px 10px 10px 0px rgba(0,0,0,0.1);
}
.logout-btn{
    background-color: #0D2457;
    color:white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
}