.head{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.full-body{
    position:relative;
    padding: 10rem;
    padding-top: 6rem;
}
/* .form{
    flex-direction: row !important;
}
.before-form{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.last-form{
    align-content: space-between;
} */
.input-field {
    margin-top: 3px;
    padding: 15px;
    font-size: 16px;
    border-radius: 3px;
    border: 1px solid #dcdcdc;
    background-color: white;
    margin-top: 20px !important;
}
/* .field-middle{
    margin-top: 80px !important;
    margin-bottom: 80px !important;
} */
.center-x-y{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.form-area-stuff{
    display:flex; 
    flex-direction:row; 
    justify-content:space-between;
}
.btn-analyse{
    background-color: #0D2457 !important;
    color:white !important;
    border: none;
    border-radius: 3px;
}
@media only screen and (max-width: 600px) {
    .full-body{
        position:relative;
        padding: 2rem;
    }
    .form-area-stuff{
        flex-direction: column;
    }
}